import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import blob from "../../assets/images/blogbob.svg"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Popper from "@material-ui/core/Popper"
import Fade from "@material-ui/core/Fade"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import RedditIcon from "@material-ui/icons/Reddit"
import LinkIcon from "@material-ui/icons/Link"
import { FiShare } from "react-icons/fi"

const primary = "#26a27b"

const query = graphql`
  {
    allContentfulPressRelease(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        shortTitle
        createdAt(formatString: "MMMM D")
        updatedAt(formatString: "MMMM D")
        body {
          body
          childMarkdownRemark {
            html
          }
        }
        tags {
          tags
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
          title
          description
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Background = styled.div`
  background-image: url(${blob});
  background-size: auto;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  background-position: 0% 0%;
  color: white;
  padding: 5rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
`

const useStyles = makeStyles((theme) => ({
  blogCol: {},
  paper: {
    borderRadius: 4,
    padding: theme.spacing(1),
    inset: `0px auto auto 0px`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `rgba(230,230,230,1) 0px 1px 4px`,
  },
  postDate: {
    color: "#757575",
    fontSize: ".9rem",
    marginBottom: ".75em",
    fontWeight: 300,
  },
  postTitle: {
    color: "#292929",
    fontSize: "2.25rem",
    fontWeight: 700,
    lineHeight: "1.25em",
    "&:hover": {
      color: primary,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.375em",
      lineHeight: "1.35em",
    },
  },
  postShortTitle: {
    paddingTop: ".75em",
    paddingBottom: "1.125em",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#757575",
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  postImage: {},
  postImgSubtitle: {
    fontWeight: 400,
    fontSize: ".8125rem",
    color: "#757575",
  },
  postBody: {
    paddingTop: "1.5em",
    color: "#292929",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    fontFamily: "Open Sans",
    "& p": {
      fontWeight: 400,
    },
    "& > p:not(:first-child)": {
      marginTop: "1.25em",
    },
    "& strong": {
      color: primary,
      fontFamily: "Open Sans",
    },
    "& ul": {
      "& li": {
        listStyleType: "square",
      },
      "& li::marker": {
        color: primary,
        fontSize: "1.25rem",
      },
      "& > li:not(:first-child)": {
        marginTop: "1.25em",
        // "& :nth-child(1)": {
        //   marginTop: 50,
        // },
      },
    },
    "& > h2, h1, h3": {
      fontFamily: "Open Sans",
      borderLeft: `4px solid ${primary}`,
      paddingLeft: 20,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.125rem",
      },
    },
  },
  postFooterLink: {
    fontWeight: 500,
    paddingTop: "1.5125em",
    fontSize: ".9125rem",
  },
}))

export default function BlogIndex() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const {
    allContentfulPressRelease: { nodes: posts },
    site: { siteMetadata: siteUrl },
  } = useStaticQuery(query)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? "transitions-popper" : undefined

  const handleShare = (e) => {
    e.preventDefault()

    switch (e.currentTarget.id) {
      case "facebook":
        let followurlf = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`

        window.open(`${followurlf}`, `_blank`)
        break
      case "twitter":
        let followurlt = `https://twitter.com/intent/tweet?text=${`Check out this article -  via Snappy Web Design `}&url=${
          window.location.href
        }`
        window.open(`${followurlt}`, `_blank`)
        break
      case "reddit":
        let encodedUrl = encodeURIComponent(window.location.href)
        let followurl = `http://www.reddit.com/submit?url=${encodedUrl}&title=${encodeURIComponent(
          "Article from Snappy Web Design"
        )}`
        window.open(`${followurl}`, `_blank`)
        break
      case "copy":
        navigator.clipboard.writeText(window.location.href)
        setAlertOpen(true)
        break
      default:
        break
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setAlertOpen(false)
  }

  return (
    <Layout>
      <Seo
        title="Press Room"
        description="The official Press Release coverage for Snappy Web Design. Read awards, coverage, and more about the Michigan Web Design company."
        image={`${siteUrl.siteUrl}/images/blog.jpg`}
      />
      <Background>
        <h1
          style={{
            color: `${primary}`,
          }}
        >
          Press Room
        </h1>
      </Background>

      <Container style={{ maxWidth: 680 }}>
        <Grid
          container
          justify="center"
          style={{ paddingTop: "4em", paddingBottom: "6.25em" }}
        >
          {posts.map((post) => {
            const image = getImage(post.image)
            const slug = slugify(post.title, { lower: true })

            return (
              <Grid item xs={12} key={post.id}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.postDate}>
                      Published {post.createdAt}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to={`/press/${slug}/`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="h6" className={classes.postTitle}>
                        {post.title}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.postShortTitle}
                    >
                      {post.shortTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <Link
                          to={`/press/${slug}/`}
                          style={{
                            textDecoration: "none",
                          }}
                          title={`Link to ${post.title}`}
                        >
                          <GatsbyImage
                            image={image}
                            imgClassName={classes.postImage}
                            alt={post.title}
                          />
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        {post.image.title && (
                          <Typography
                            className={classes.postImgSubtitle}
                            align="center"
                            variant="subtitle2"
                          >
                            {post.image.title}
                          </Typography>
                        )}
                      </Grid>
                      {post.image.description && (
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            align="center"
                            className={classes.postImgSubtitle}
                          >
                            {post.image.description}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      variant="body1"
                      className={classes.postBody}
                      dangerouslySetInnerHTML={{
                        __html:
                          post.body.childMarkdownRemark.html.substring(0, 500) +
                          "...",
                      }}
                    ></div>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to={`/press/${slug}/`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        variant="body1"
                        className={classes.postFooterLink}
                      >
                        Read more ·{" "}
                        {Math.round(post.body.body.split(" ").length / 200)}{" "}
                        minute read
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "2em" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <FiShare
                            onClick={handleClick}
                            size={20}
                            style={{
                              color: "rgba(41,41,41,1)",
                              strokeWidth: 1.5,
                              cursor: "pointer",
                              marginRight: 7,
                            }}
                          />{" "}
                          <Typography
                            variant="body1"
                            onClick={handleClick}
                            style={{
                              cursor: "pointer",
                              marginTop: -2,
                              fontWeight: 300,
                              color: "#444",
                            }}
                          >
                            Share
                          </Typography>
                          <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            transition
                          >
                            {({ TransitionProps }) => (
                              <ClickAwayListener
                                onClickAway={() => setAnchorEl(false)}
                              >
                                <Fade {...TransitionProps} timeout={350}>
                                  <List dense={true} className={classes.paper}>
                                    <ListItem
                                      button
                                      style={{ paddingTop: ".75em" }}
                                      id="facebook"
                                      onClick={handleShare}
                                    >
                                      <ListItemIcon>
                                        <FacebookIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Facebook" />
                                    </ListItem>
                                    <ListItem
                                      button
                                      style={{ paddingTop: ".75em" }}
                                      id="twitter"
                                      onClick={handleShare}
                                    >
                                      <ListItemIcon>
                                        <TwitterIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Twitter" />
                                    </ListItem>
                                    <ListItem
                                      button
                                      style={{ paddingTop: ".75em" }}
                                      id="reddit"
                                      onClick={handleShare}
                                    >
                                      <ListItemIcon>
                                        <RedditIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Reddit" />
                                    </ListItem>
                                    {!matchesXS && (
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="copy"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <LinkIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Copy Link" />
                                      </ListItem>
                                    )}
                                  </List>
                                </Fade>
                              </ClickAwayListener>
                            )}
                          </Popper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  style={{ marginTop: "3.125em", marginBottom: "3.125em" }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Link copied
        </Alert>
      </Snackbar>
    </Layout>
  )
}
